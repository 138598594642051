<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
<div>
  <div class="lockscreen-logo">
    <a href="/soal-mapel">LOGO TARUNA</a>
  </div>
  <!-- User name -->
  <div class="text-center"><h4>IMG</h4></div>
  <br>
  <div class="text-center"><h6>NAMA SISWA</h6></div>
  

  <br>
  <br>
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
  <div class="text-center">
    <p>Kamu telah sampai di halaman terakhir. Silahkan kembali jika masih perlu melakukan pengecekan ulang selama waktunya masih ada. Jika sudah selesai maka silahkan klik Submit untuk menutup ujian secara manual.</p>
  </div>
        </div>
        <div class="col-2"></div>
    </div>
  <div class="text-center">
      <a href="/soal-process" class="btn btn-lg btn-secondary"><i class="fas fa-arrow-left"></i> Kembali ke Latihan Soal</a>
      <br><br>
      <a href="/soal-mapel" class="btn btn-lg btn-primary"><i class="fas fa-arrow-right"></i> Submit Sekarang</a>
  </div>  
</div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
    name: 'SoalFinish',
    data() {
        return {
            
        };
    }
}
</script>
<style type="text/css" scoped>
</style>